import React, { useState, useEffect } from 'react';
import Menu from '../../components/Navbar/Menu';
import Footer from '../../components/Fotter/footer';
import Whatsapp from '../../components/Whatsapp/whatsapp';

const CommentForm = () => {
  const [comments, setComments] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [comment, setComment] = useState('');

  // Cargar comentarios almacenados al cargar el componente
  useEffect(() => {
    const storedComments = JSON.parse(localStorage.getItem('comments')) || [];
    setComments(storedComments);
  }, []);

  // Guardar comentarios en localStorage cada vez que cambian
  useEffect(() => {
    localStorage.setItem('comments', JSON.stringify(comments));
  }, [comments]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const newComment = { name, email, comment };
    setComments([...comments, newComment]);
    setName('');
    setEmail('');
    setComment('');
  };

  return (
    <div>
      <Menu/>
      <Whatsapp/>
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Deja un comentario</h5>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Nombre</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Correo Electrónico</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="comment" className="form-label">Comentario</label>
              <textarea
                className="form-control"
                id="comment"
                rows="3"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                required
              ></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Publicar</button>
          </form>
        </div>
      </div>

      <div className="mt-5">
        <h5>Comentarios</h5>
        {comments.map((comment, index) => (
          <div key={index} className="card mt-3">
            <div className="card-body">
              <h6 className="card-title">{comment.name}</h6>
              <h6 className="card-subtitle mb-2 text-muted">{comment.email}</h6>
              <p className="card-text">{comment.comment}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className='mt-5'>
    <Footer/>
    </div>
    </div>
  );
};

export default CommentForm;
