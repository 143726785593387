import React from 'react';
import { Carousel, Image, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const CommentCarousel = ({ comments }) => {
  return (
    <Carousel>
      {comments.map((comment, index) => (
        <Carousel.Item key={index}>
          <Container className="text-center my-4">
            <Row className="justify-content-center">
              <Col md={8}>
                <h4>{comment.text}</h4>
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center mt-4">
              <Col xs={12} className="d-flex flex-column align-items-center">
                <Image
                  src={comment.photo}
                  roundedCircle
                  fluid
                  className="mb-3"
                  style={{ width: '100px', height: '100px' }}
                />
                <h5>{comment.name}</h5>
                <p>{comment.city}</p>
              </Col>
            </Row>
          </Container>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CommentCarousel;

