import React from 'react';
import Menu from '../../components/Navbar/Menu';
import Cookies from '../../components/cookies/cookies';
import Whatsapp from '../../components/Whatsapp/whatsapp';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/Button';
import Footer from '../../components/Fotter/footer';
import { Link } from "react-router-dom"

import carousel1 from '../../assets/2.png';
import carousel2 from '../../assets/3.png';
import carousel3 from '../../assets/4.png';

import video from '../../assets/VID1.mp4';
import videoPoster from '../../assets/carousel1.png';

import d from '../../assets/d.png';
import e from '../../assets/e.png';
import f from '../../assets/f.png';

import './Home.css';

const Home = () => {
  return (
    <div>
      <Menu/>
      <Cookies/>
      <Whatsapp/>
      <Carousel data-bs-theme="dark">
  <Carousel.Item>
    <img className="d-block w-100" src={carousel1} alt="First slide" />
    <Carousel.Caption>
      <h5 className="titleca">Organización Sindical</h5>
      <p className="text-white d-none d-md-block">
      Operar garantía y contragarantía, los cuales serán el eje de la estrategia 
      de financiamiento del Fideicomiso, así como el diseño y operación de 
      programas financieros que tengan por objeto contribuir a generar un mayor 
      desarrollo económico en la empresa y la economía familiar.
    </p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img className="d-block w-100" src={carousel2} alt="Second slide" />
    <Carousel.Caption>
      <h5 className="titleca">Programas para Mujer</h5>
      <p className="text-white d-none d-md-block">
      Ser la entidad de Fomento sólida, capitalizada, profesional, con 
sensibilidad social para los inversionistas y productores de la Republica 
mexicana, garantizando la aplicación de recursos para otorgar 
Financiamientos en las condiciones más favorables.
    </p>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item>
    <img className="d-block w-100" src={carousel3} alt="Third slide" />
    <Carousel.Caption>
      <h5 className="titleca">Programas para Emprendedor</h5>
      <p className="text-white d-none d-md-block">
      Facilitar el acceso al Financiamiento a Mujeres, a través de créditos y 
Microcréditos, que impulsen y fomenten nuevas iniciativas productivas 
para la conservación, mejoramiento y generación de empleos en la 
Micro, Pequeñas y Medianas Empresas de la republica mexicana.
    </p>
    </Carousel.Caption>
  </Carousel.Item>
</Carousel>


      <div className='SECTION 1 my-5'>
      <Container>
          <div className='Prmparrafo'>
            <p className='mb-4'>
              Somos la <strong>Organizacion Empresarial</strong> que unifica a empresarios a nivel nacional de todas los giros comerciales del país, sin importar nivel económico y antigüedad, así como empresas micro, pequeñas, mediana y grande, con la finalidad de representar, impulsar, defender sus intereses, así como fortalecer, desarrollar y consolidar estrategias para su crecimiento.
            </p>
            <p>
              Estamos seguros del potencial de México, por ello ser el principal referente de la cultura empresarial y social en México, que guíe y participe a través de capacitación, representación, soporte jurídico, asistencia y financiamientos para lograr así, incrementar la productividad y contribuir al desarrollo.
            </p>
          </div>

          <div className="SECTION 2 video-presentation-container">
            <div className="video-wrapper">
              <video className="video-element" controls poster={videoPoster}>
                <source src={video} type="video/mp4" width="100%" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <div className='SECTION 2 my-5'>
            <div className='text-center'>
              <h2 className='titlesec2'>AFILIARSE A LA ORGANIZACION</h2>
              <p className='parrasec2'>
                Sé partícipe de la Asociación a través de capacitación, representación, soporte jurídico, asistencia y financiamientos para lograr así, incrementar la productividad y contribuir al desarrollo.
              </p>
              <Button as={Link} to="/Afiliacion" className='buttonsec2 my-3' size="lg">AFILIARZE AHORA</Button>
            </div>

            <div className='SECTION 3 my-5'>
              <div className="container">
                <div className="row">
                  {/* Primera columna */}
                  <div className="col-md-4 text-center">
                    <img src={d} alt="Imagen 1" className="img-fluid imgd" />
                    <h2 className="mt-3">Abogando por tus Derechos</h2>
                    <p>
                    Nuestro compromiso es estar a tu lado, proporcionándote la asistencia necesaria para resolver cualquier duda que puedas tener.                    </p>
                  </div>
                  {/* Segunda columna */}
                  <div className="col-md-4 text-center">
                    <img src={e} alt="Imagen 2" className="img-fluid imgd" />
                    <h2 className="mt-3">Justicia y Equidad</h2>
                    <p>Nos esforzamos por establecer un entorno laboral saludable en el que se promuevan beneficios equitativos para la empresa, los trabajadores y el sindicato.</p>
                  </div>
                  {/* Tercera columna */}
                  <div className="col-md-4 text-center">
                    <img src={f} alt="Imagen 3" className="img-fluid imgd" />
                    <h2 className="mt-3">Optimizando el Entorno Laboral</h2>
                    <p>Trabajamos incansablemente para garantizar que los trabajadores sean recompensados de manera justa por su desempeño y contribución al éxito de la empresa.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
