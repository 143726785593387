import React, { useState } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import './objetivos.css'; 
import { Link } from "react-router-dom"
import Menu from '../../components/Navbar/Menu';
import Footer from '../../components/Fotter/footer';
import o1 from '../../assets/o1.jfif';
import o2 from '../../assets/o2.jfif';
import o3 from '../../assets/o3.jfif';
import o4 from '../../assets/o4.jfif';
import video from '../../assets/VID2.mp4';
import videoPoster from '../../assets/carousel1.png';
import Whatsapp from '../../components/Whatsapp/whatsapp';

const Objetivos = () => {
  const [activeButton, setActiveButton] = useState('gallery1');

  const handleButtonClick = (gallery) => {
    setActiveButton(gallery);
  };

  const renderContent = () => {
    switch (activeButton) {
      case 'gallery1':
        return (
          <Card className="my-4" style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
            <Row>
              <Col md={4}>
                <img
                  src={o1}
                  alt="CAPACITACION"
                  style={{ width: '100%', height: 'auto' }}
                />
              </Col>
              <Col md={8} className="d-flex flex-column justify-content-center botno">
                <h3>CAPACITACION</h3>
                <p>Agradecemos a los trabajadores y sus logros.</p>
                <Button as={Link} to="/Afiliacion" className="mx-auto custom-button">
                 Más Información
              </Button>
              </Col>
            </Row>
          </Card>
        );
      case 'gallery2':
        return (
          <Card className="my-4" style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
            <Row>
              <Col md={4}>
                <img
                  src={o2}
                  alt="REPRESENTACION Y DEFENSA"
                  style={{ width: '100%', height: 'auto' }}
                />
              </Col>
              <Col md={8} className="d-flex flex-column justify-content-center botno">
                <h3>ASESORIA DE AREA JURIDICA</h3>
                <p>Capacitación Jurídica</p>
                <Button as={Link} to="/Afiliacion" className="mx-auto custom-button">
                 Más Información
              </Button>
              </Col>
            </Row>
          </Card>
        );
      case 'gallery3':
        return (
          <Card className="my-4" style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
            <Row>
              <Col md={4}>
                <img
                  src={o3}
                  alt="ASESORIA FISCAL"
                  style={{ width: '100%', height: 'auto' }}
                />
              </Col>
              <Col md={8} className="d-flex flex-column justify-content-center botno">
                <h3>CONTRATOS COLECTIVOS A NIVEL NACIONAL</h3>
                <p>Abriendo y Expandiendo Contratos Colectivos</p>
                <Button as={Link} to="/Afiliacion" className="mx-auto custom-button">
                 Más Información
              </Button>
              </Col>
            </Row>
          </Card>
        );
      case 'gallery4':
        return (
          <Card className="my-4" style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>
            <Row>
              <Col md={4}>
                <img
                  src={o4}
                  alt="FINANCIAMIENTOS"
                  style={{ width: '100%', height: 'auto' }}
                />
              </Col>
              <Col md={8} className="d-flex flex-column justify-content-center botno">
                <h3>APOYO Y CRECIMIENTO PARA TU NEGOCIO</h3>
                <p>Siempre Apoyando tu Crecimiento</p>
                <Button as={Link} to="/Afiliacion" className="mx-auto custom-button">
                 Más Información
              </Button>
              </Col>
            </Row>
          </Card>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Menu/>
      <Whatsapp/>
      <Container className='py-5 text-center'>
        <h2>SE HACE UN GRAN RECONOCIMIENTO A LOS EMPRENDEDORES CAPACITADOS EN LA ÚLTIMA JORNADA</h2>
        <Row className="my-5 justify-content-center">
          <Col xs="12" sm="auto" className='botn mx-2 mb-2'>
            <Button onClick={() => handleButtonClick('gallery1')}>
              CAPACITACION
            </Button>
            {activeButton === 'gallery1' && <div className="arrow"></div>}
          </Col>
          <Col xs="12" sm="auto" className='botn mx-2 mb-2'>
            <Button variant="secondary" onClick={() => handleButtonClick('gallery2')}>
            REPRESENTACION Y DEFENSA
            </Button>
            {activeButton === 'gallery2' && <div className="arrow"></div>}
          </Col>
          <Col xs="12" sm="auto" className='botn mx-2 mb-2'>
            <Button variant="success" onClick={() => handleButtonClick('gallery3')}>
            ASESORIA FISCAL
            </Button>
            {activeButton === 'gallery3' && <div className="arrow"></div>}
          </Col>
          <Col xs="12" sm="auto" className='botn mx-2 mb-2'>
            <Button variant="danger" onClick={() => handleButtonClick('gallery4')}>
            FINANCIAMIENTOS
            </Button>
            {activeButton === 'gallery4' && <div className="arrow"></div>}
          </Col>
        </Row>
        {renderContent()}
        <div className="video-presentation-container">

  <video className="video-elementt" controls poster={videoPoster}>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>  
             </div>

      </Container>
      <Footer/>
    </div>
  );
};

export default Objetivos;
