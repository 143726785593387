import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Outlet, Link } from "react-router-dom"
import './Menu.css'; 
import barraDecorativa from '../../assets/barra.png'; // Importa la imagen
import NavDropdown from 'react-bootstrap/NavDropdown'; // Importa NavDropdown


const Menu = () => {
    return (
      <div className='menuf'>
        
        <Navbar bg="transparent" expand="lg" >
        <Container>
        <Navbar.Brand as={Link} to="/" >
          {/* <img alt="logo" src={logoo} width="80" height="auto" /> */}
        </Navbar.Brand>
          {/* <div className='namelogo'>Asociacion de Emprendedores</div>  */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">    
          <Nav.Link as={Link} to="/" className='navLinkStyle'>INICIO</Nav.Link>

              <NavDropdown title="SERVICIOS" className='navLinkStyle' id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/Objetivos" className='navLinkStylee'>OBJETIVOS</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/Financiamiento" className='navLinkStylee'>FINANCIAMIENTO</NavDropdown.Item>
              </NavDropdown>    
                <Nav.Link as={Link} to="/Afiliacion" className='navLinkStyle'>AFILIACION</Nav.Link>
                <Nav.Link as={Link} to="/Estructura" className='navLinkStyle'>ESTRUCTURA</Nav.Link>
                <Nav.Link as={Link} to="/Difusion" className='navLinkStyle'>DIFUSION</Nav.Link>
                <Nav.Link as={Link} to="/Blog" className='navLinkStyle'>BLOG</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
        </Navbar>
   <div className="barra-decorativa-container">
        <img src={barraDecorativa} alt="Decoración" className="barra-decorativa " />
      </div>       
      
       <section>
        <Outlet>


          
        </Outlet>
    </section> 
    </div>
    );
  };
  
  export default Menu;
