import React from 'react';
import '../Estructura/Estructura.css';
import Menu from '../../components/Navbar/Menu';
import Footer from '../../components/Fotter/footer';
// import fotoa from '../../assets/fotop.jpeg';
// import separador from '../../assets/separacion.png';
import { Container, Row, Col } from 'react-bootstrap';
import Whatsapp from '../../components/Whatsapp/whatsapp';


const Estructura = () => {
 

  return (
    <div>
    <Menu />
    <Whatsapp/>
    <div className="background-wrapper">
      <div className="background"></div>
      <div className="content m-5">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={4} className="text-center my-3">
              {/* <Image
                src={fotoa}
                roundedCircle
                style={{ width: '30%', height: 'auto', marginBottom: 0 }}
                fluid
              /> */}
            </Col>
            <Col xs={12} md={8} className="d-flex text-center textoflex">
              <div className="ml-md-3">
                <h2 className="mb-0">Secretaría General Adjunta</h2>
                <p className="mt-2 mb-0">Roberto Francisco Islas Shaw</p>
              </div>
            </Col>
            <Col xs={12} md={4} className="text-center my-3">
              {/* <Image
                src={separador}
                style={{ width: '30%', height: 'auto' }}
                fluid
              /> */}
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col xs={12} md={4} className="text-center">
              {/* <Image
                src={fotoa}
                roundedCircle
                style={{ width: '30%', height: 'auto' }}
                fluid
              /> */}
            </Col>
            <Col xs={12} md={8} className="d-flex text-center textoflex">
              <div className="ml-md-3">
                <h2 className="mb-0">Secretaría de Actas y Acuerdos</h2>
                <p className="mt-2 mb-0">Ramiro Saucedo Treviño</p>
              </div>
            </Col>
            <Col xs={12} md={4} className="text-center my-3">
              {/* <Image
                src={separador}
                style={{ width: '30%', height: 'auto' }}
                fluid
              /> */}
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col xs={12} md={4} className="text-center">
              {/* <Image
                src={fotoa}
                roundedCircle
                style={{ width: '30%', height: 'auto', marginBottom: 0 }}
                fluid
              /> */}
            </Col>
            <Col xs={12} md={8} className="d-flex text-center textoflex">
              <div className="ml-md-3">
                <h2 className="mb-0">Secretaría de Defensa y Derecho</h2>
                <p className="mt-2 mb-0">Dulce Carolina Cepeda Arredondo</p>
              </div>
            </Col>
            <Col xs={12} md={4} className="text-center my-3 circlex">
              {/* <Image
                src={separador}
                style={{ width: '30%', height: 'auto' }}
                fluid
              /> */}
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col xs={12} md={4} className="text-center">
              {/* <Image
                src={fotoa}
                roundedCircle
                style={{ width: '30%', height: 'auto' }}
                fluid
              /> */}
            </Col>
            <Col xs={12} md={8} className="d-flex text-center textoflex">
              <div className="ml-md-3">
                <h2 className="mb-0">Secretaría de Prensa y Difusión</h2>
                <p className="mt-2 mb-0">Edgar Vergara Tadeo</p>
              </div>
            </Col>
            <Col xs={12} md={4} className="text-center my-3">
              {/* <Image
                src={separador}
                style={{ width: '30%', height: 'auto' }}
                fluid
              /> */}
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col xs={12} md={4} className="text-center">
              {/* <Image
                src={fotoa}
                roundedCircle
                style={{ width: '30%', height: 'auto', marginBottom: 0 }}
                fluid
              /> */}
            </Col>
            <Col xs={12} md={8} className="d-flex text-center textoflex my-4">
              <div className="ml-md-3">
                <h2 className="mb-0">Secretaría de Economía y Finanzas</h2>
                <p className="mt-2 mb-0">Gabriela Cristina Diaz Probo</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    <Footer />
  </div>
  );
};

export default Estructura;
