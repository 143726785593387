import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Fotter/footer.css';  // Asegúrate de tener este archivo CSS
import barraDecorativa from '../../assets/barra2.png'; // Importa la imagen
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import stps from '../../assets/stps.png'
import {Link } from "react-router-dom"

const Footer = () => {
  return (
    <footer className='Contfooter'> 
      <div className="footer-bar">
        <img src={barraDecorativa} alt="Decorativa" className="img-fluid" />
      </div>
      
      {/* Contenido del Footer */}
      <div className="container mt-4">
        <div className="row">
          {/* Primera fila */}
          <div className="col-md-4 text-center ">
                <h5 className='my-2'>Mision</h5> 
                <p>Nuestra mision es incentivar el crecimiento económico nacional, regional y sectorial, mediante el fomento a la productividad e innovación de las micro, pequeñas y mediana empresas ubicadas en sectores estratégicas, que impulse el fortalecimiento ordenado, planificado y sistemático del emprendimiento y del desarrollo empresarial en todo el territorio nacional, así como la consolidación de una economía innovadora, dinámica y competitiva.</p>       

          </div>
          
       {/* Segunda fila */}
       <div className="col-md-4 text-center">
            <h5>Contacto</h5>
            <p><LocationOnIcon />Av. Luis Pasteur Sur 263, zona dos extendida, Mercurio, 76046 Santiago de Querétaro, Qro.</p>
            <p><LocationOnIcon />Av. Dr. Río de la Loza 68, Doctores, Cuauhtémoc, 06720 Ciudad de México, CDMX</p>
            <p><LocationOnIcon />Carr. Picacho-Ajusco 714, Fuentes del Pedregal, Tlalpan, 14209 Ciudad de México, CDM</p>
            <p><EmailIcon />contacto@organizacionsindical.org.mx</p>
            <p><EmailIcon />secretariadefinanzas@organizacionsindical.org.mx</p>
            <p><PhoneIcon />+55-93-31-47-87</p>
          </div>
          
          {/* Tercera fila */}
          <div className="col-md-4 text-center">
            <h5>Nosotros</h5>
            <p>Si compartes nuestra visión de un entorno empresarial inclusivo y próspero, te invitamos a unirte a nosotros. Juntos, construiremos un futuro donde cada idea, por más pequeña que sea, tenga el potencial de transformarse en una historia de éxito.</p>
            <img src={stps} alt="logo" width="80%" className='img-fluid my-3 mx-auto ' />  

          </div>
        </div>
      </div>
      
      {/* Barra inferior decorativa */}
      <div className="footer-bottom text-center py-3">
        <p className="mb-0">© 2018 Organizacion Sindical</p> 
        <p><Link to="/Aviso">Aviso de Privacidad</Link></p>       
        <p>Organizacion.</p>
      </div>
    </footer>
  );
};

export default Footer;
