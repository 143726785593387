import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Financiamiento.css';
import Menu1 from '../../components/Menu1/menu1';
import Menu2 from '../../components/Menu2/menu2';
import Menu from '../../components/Navbar/Menu';
import Footer from '../../components/Fotter/footer';
import Comentarios from '../../components/comentarios/comentarios';
import video1 from '../../assets/v1.mp4';
import video2 from '../../assets/v2.mp4';
import video3 from '../../assets/v3.mp4';
import video4 from '../../assets/v4.mp4';
import video5 from '../../assets/v5.mp4';
import video6 from '../../assets/v6.mp4';
import f1 from '../../assets/f1.jfif'
import f2 from '../../assets/f2.jfif'
import f3 from '../../assets/f3.jfif'
import Whatsapp from '../../components/Whatsapp/whatsapp';



const comments = [
  {
    text: "Gracias al programa por su apoyo",
    photo: f1,
    name: "Juan Pérez",
    city: "Ciudad de México"
  },
  {
    text: "el sindicato me ayudo aponer mi empresa de agua purificadora",
    photo: f2,
    name: "María García",
    city: "Estado de Mexico"
  },
  {
    text: "gracias al programa capitaliza tu negocio incremente mis ventas en mi spa muchas gracias",
    photo: f3,
    name: "María García",
    city: "Puebla"
  },
  // Agrega más comentarios según sea necesario
];
const videoUrls = [video1, video2, video3, video4,video5,video6];
const videoPairs = [];
  for (let i = 0; i < videoUrls.length; i += 2) {
    videoPairs.push(videoUrls.slice(i, i + 2));
  }
function Financiamiento() {
  return (
    <div className='Financiamiento'>
      <Menu />
      <Whatsapp/>
      <Container className="mt-3">
        <Row className="mb-4">
          <Col>
            <h1 className="text-center">Programas y Desarrollos</h1>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <p className="text-center">Es importante destacar la participación que tienen el sindicato para garantizar y promover programas y créditos a si mismo la protección de los derechos laborales de las mujeres así como asegurar su trato justo y equitativo en aquellas empresas que ven a México como el lugar perfecto para relocalizar su cadena de suministro.</p>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-4">
            <h2 className="text-center">Programa Personal</h2>
            <Card className="shadow mb-3 cardd mx-auto" style={{ border: '3px solid #fff', backgroundColor: '#CFB787' }}>
              <Card.Body className='cartex'>
              <p>Conseguir apoyos para comercio local y nacional Aprovecha las oportunidades que existen en el mercado interno para comercializar tus productos y servicios.</p>
              </Card.Body>
            </Card>
            <p>Financiamiento a Telecomunicación PYME
            El programa financia a medianas y pequeñas empresas, incluidas personas físicas con actividad empresarial y RIF., que participan en los sectores de las industrias digitales e infraestructura de telecomunicaciones.</p>
          </Col>
          <Col md={6} className="mb-4">
            <h2 className="text-center">Empresariales</h2>
            <Card className="shadow mb-3 cardd mx-auto" style={{ border: '3px solid #fff', backgroundColor: '#CFB787' }}>
              <Card.Body className='cartex'>
                <p>Financiamiento a Telecomunicación PYME
                El programa financia a medianas y pequeñas empresas, incluidas personas físicas con actividad empresarial.</p>
              </Card.Body>
            </Card>
           <p>Crédito Simple
           Da financiamiento a requerimientos de capital de trabajo para la producción de bienes, prestación de servicios o comercio y adquisición de activos fijos.</p>
          </Col>
        </Row>
        
        <Row className="mb-4">
          <Col>
            <h1 className="text-center">Organización Sindical</h1>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mb-4 ">
            <p>Este programa busca apoyar e impulsar al emprendedor en mejorar, ampliar e incentivar la cultura empresarial en México. Cada vez más personas se proponen ser emprendedores y lanzan emprendimientos en diferentes ámbitos, pero necesitan de ayuda.</p>
            <p>El recurso para otorgar financiamientos a este programa es derivado a la suma de los aportes que efectúan todos y cada una de las personas afiliadas a la Organización sindical de Mexico que tiene como objeto incentivar el crecimiento económico nacional, mediante otorgamiento de Financiamiento para la productividad e innovación en las micro, pequeñas y medianas empresas, que impulse el fortalecimiento del emprendimiento y del desarrollo empresarial en todo el territorio nacional.</p>
            <Row>
              <Col md={6} className="mb-4">
                <Card className="shadow mx-auto" style={{ backgroundColor: '#fff', border: '3px solid #CFB787', borderRadius: '20px' }}>
                  <Card.Body className='cartexx'>
                    <p>Programa para Empresas Culturales.</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="mb-4">
                <Card className="shadow mx-auto" style={{ backgroundColor: '#fff', border: '3px solid #CFB787', borderRadius: '20px' }}>
                  <Card.Body className='cartexx'>
                    <p>Programa para Sociedades Cooperativas.                    </p>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6} className="mb-4">
                <Card className="shadow mx-auto" style={{ backgroundColor: '#fff', border: '3px solid #CFB787', borderRadius: '20px' }}>
                  <Card.Body className='cartexx'>
                    <p>Programa para pyme.</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="mb-4">
                <Card className="shadow mx-auto" style={{ backgroundColor: '#fff', border: '3px solid #CFB787', borderRadius: '20px' }}>
                  <Card.Body className='cartexx'>
                    <p>Programa para comerciantes.</p>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6} className="mb-4">
                <Card className="shadow mx-auto" style={{ backgroundColor: '#fff', border: '3px solid #CFB787', borderRadius: '20px' }}>
                  <Card.Body className='cartexx'>
                    <p>Programa para la Comercialización de Productos Rurales.</p>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} className="mb-4">
                <Card className="shadow mx-auto" style={{ backgroundColor: '#fff', border: '3px solid #CFB787', borderRadius: '20px' }}>
                  <Card.Body className='cartexx'>
                    <p>Programa para Mujeres Empresarias.</p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Menu2/>
          </Col>

          
          <Col md={6}>
            <p>Este programa es complementario del esquema de financiamiento para emprendedores, los emprendedores deberán ser miembro Activo a la Asociación y tener cubierto todos los créditos de la capacitación en línea desde el portal de la Red de la Asociación</p>
            <p>Luego de acreditar la capacitación (en el “Programa”), accederán a la red de promotoras para la integración de su expediente y solicitud ante la Asociación, que canalizarán el proyecto a un Comité de evaluación que los revisará y validará</p>
            <Menu1 />
          </Col>
        </Row>
        <Comentarios comments={comments} />
        
      
        <Row>
        {videoUrls.map((url, index) => (
          <Col key={index} xs={12} md={6} lg={4} className="mb-4">
            <div className="embed-responsive embed-responsive-16by9">
              <video
                src={url}
                className="embed-responsive-item"
                controls
                style={{ width: '100%', height: 'auto' }}
              />
            </div>
          </Col>
        ))}
      </Row>
      

        </Container>
      <Footer />
    </div>
  );
}

export default Financiamiento;


