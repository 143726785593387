import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import './Difusion.css'; 
import Menu from '../../components/Navbar/Menu';
import Footer from '../../components/Fotter/footer';
//galeria1
import d1 from '../../assets/d1.jfif';
import d2 from '../../assets/d2.jfif';
import d3 from '../../assets/d3.jfif';
import d4 from '../../assets/d4.jfif';
import d5 from '../../assets/d5.jfif';
import d6 from '../../assets/d6.jfif';
import d7 from '../../assets/d7.jfif';
//galeria2
import e1 from '../../assets/e1.jfif';
import e2 from '../../assets/e2.jfif';
import e3 from '../../assets/e3.jfif';
import e4 from '../../assets/e4.jfif';
import e5 from '../../assets/e5.jfif';
//gleria3
import a1 from '../../assets/a1.jfif';
import a2 from '../../assets/a2.jfif';
import a3 from '../../assets/a3.jfif';
import a4 from '../../assets/a4.jfif';
import a5 from '../../assets/a5.jfif';
import a6 from '../../assets/a6.jfif';
import a7 from '../../assets/a7.jfif';
import a8 from '../../assets/a8.jfif';
import a9 from '../../assets/a9.jfif';
import a10 from '../../assets/a10.jfif';
import a11 from '../../assets/a11.jfif';
import a12 from '../../assets/a12.jfif';
import a13 from '../../assets/a13.jfif';
import a14 from '../../assets/a14.jfif';
//galeria4
import p1 from '../../assets/p1.jfif';
import p2 from '../../assets/p2.jfif';
import p3 from '../../assets/p3.jfif';
import p4 from '../../assets/p4.jfif';
import p5 from '../../assets/p5.jfif';
import p6 from '../../assets/p6.jfif';
import p7 from '../../assets/p7.jfif';
import p8 from '../../assets/p8.jfif';
import p9 from '../../assets/p9.jfif';
import p10 from '../../assets/p10.jfif';
import p11 from '../../assets/p11.jfif';
import p12 from '../../assets/p12.jfif';
import p13 from '../../assets/p13.jfif';
import p14 from '../../assets/p14.jfif';
import p15 from '../../assets/p15.jfif';
import p16 from '../../assets/p16.jfif';
import Whatsapp from '../../components/Whatsapp/whatsapp';




const Difusion = () => {
  const [activeGallery, setActiveGallery] = useState('gallery1');
  const [activeButton, setActiveButton] = useState('gallery1');

  const galleries = {
    gallery1: [d1, d2, d3, d4, d5, d6, d7],
    gallery2: [e1, e2, e3, e4, e5],
    gallery3: [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13, a14],
    gallery4: [p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11,p12,p13,p14,p15,p16]
  };

  const handleButtonClick = (gallery) => {
    setActiveGallery(gallery);
    setActiveButton(gallery);
  };

  return (
    <div>
      <Menu/>
      <Whatsapp/>
      <Container className='py-5 text-center'>
        <h2>SE HACE UN GRAN RECONOCIMIENTO A LOS EMPRENDEDORES CAPACITADOS EN LA ÚLTIMA JORNADA</h2>
        <Row className="my-5 justify-content-center">
          <Col xs="12" sm="auto" className='botn mx-2 mb-2'>
            <Button onClick={() => handleButtonClick('gallery1')}>
            Capacitaciones a obreros
            </Button>
            {activeButton === 'gallery1' && <div className="arrow"></div>}
          </Col>
          <Col xs="12" sm="auto" className='botn mx-2 mb-2'>
            <Button variant="secondary" onClick={() => handleButtonClick('gallery2')}>
              <span>Evento de Fin de Año</span>
            </Button>
            {activeButton === 'gallery2' && <div className="arrow"></div>}
          </Col>
          <Col xs="12" sm="auto" className='botn mx-2 mb-2'>
            <Button variant="success" onClick={() => handleButtonClick('gallery3')}>
              <span>Apoyos al Trabajador</span>
            </Button>
            {activeButton === 'gallery3' && <div className="arrow"></div>}
          </Col>
          <Col xs="12" sm="auto" className='botn mx-2 mb-2'>
            <Button variant="danger" onClick={() => handleButtonClick('gallery4')}>
            <span>Siempre contigo y</span><br/><span>Publicación e congresos</span>
            </Button>
            {activeButton === 'gallery4' && <div className="arrow"></div>}
          </Col>
        </Row>
        <Row className="justify-content-center">
          {galleries[activeGallery].map((image, index) => (
            <Col key={index} xs={12} md={4} className="mb-4">
              <img src={image} alt={`Gallery ${activeGallery} ${index}`} className="img-fluid" />
            </Col>
          ))}
        </Row>
      </Container>
      <Footer/>
    </div>
  );
};

export default Difusion;