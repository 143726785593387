import React from 'react';
//Importaciones de Menu
import {  Container } from 'react-bootstrap';
import Menu from '../../components/Navbar/Menu';
import Footer from '../../components/Fotter/footer';
const Privacy = () => {
  return (
       <div>
        <Menu />
<section className='m-5'>
    <Container>
    <div>
        <h1 className='pb-3 fw-bold '> Aviso de Privacidad </h1>
<p>Con fundamento en los artículos 15, 16, y demás aplicables de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, se le hace entrega del presente “Aviso de Privacidad”, por el que se le informa lo siguiente:</p>    
<p>Su privacidad y confianza son muy importantes para la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA por ello, queremos asegurarnos de que conozca cómo salvaguardamos la integridad, privacidad y protección de sus datos personales, en apego a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, y su Reglamento.</p>
<p>Para la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA, resulta necesaria la recopilación de ciertos datos personales para llevar a cabo las actividades intrínsecas a su servicio.</p>
<p>La FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA, tiene la obligación legal y social de cumplir con las medidas, legales y de seguridad suficientes para proteger aquellos datos personales que haya recabado para las finalidades que en el presente Aviso de Privacidad serán descritas. </p>
<p>Todo lo anterior se realiza con el objetivo de que usted tenga pleno control y decisión sobre sus datos personales. Por ello, le recomendamos que lea atentamente la siguiente información.</p>
<ul>
  <li>Datos del Responsable:</li>
  <p>La FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA es la unión o agrupación de varios sindicatos constituida de conformidad con las leyes de los Estados Unidos Mexicanos con domicilio en Francisco Olaguibel 82-A, Colonia Obrera, C.P. 06800, Ciudad de México.</p>
  <li>Datos Personales</li>
  <p>La FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA recabará de usted datos personales o de la empresa ("Datos Personales") que sean necesarios para la adecuada prestación de sus servicios en el campo del sindicalismo en México, ya sea directa o personalmente a través de los sindicatos agrupados, secretarios generales, delegados sindicales, abogados, personal y asistentes. </p>
  <p>Dichos Datos Personales podrán incluir todos o algunos de los siguientes: </p>
  <li>1.-Datos de identificación: actas constitutivas e instrumentos notariales, nombre completo, dirección, teléfono de casa, celular y/o de trabajo, en su caso, firma, firma electrónica, Registro Federal de Contribuyentes (RFC), Clave Única de Registro Poblacional (CURP), así como identificaciones oficiales vigentes.</li>
  <li>2.-Datos laborales: ocupación, nombre de la empresa o dependencia, puesto, área o departamento, domicilio, teléfono y correo electrónico de trabajo. 
 <li>3.-Datos patrimoniales: la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA no recabará ni tratará datos personales sensibles.</li>
  <li>4.-Vías para la solicitud de datos personales: la información referida en los numerales anteriores, podrá ser recabada por la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA de manera presencial (directa) y/o vía correo electrónico y/o vía web y/o vía telefónica y/o por cualquier otro conducto que para tales efectos diseñe o destine la misma.</li>
  </li>
 <li>3. Finalidades primarias del Tratamiento de Datos
 </li>
  <p>La FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA tratará sus Datos Personales única y exclusivamente conforme a los términos del presente Aviso de Privacidad. Los Datos Personales o de la Empresa que nos ha proporcionado serán utilizados para las siguientes finalidades primarias, necesarias y que dan origen a la relación sindical que existe entre la  FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA en su carácter de entidad responsable y Usted como titular de los datos personales: 
  </p>
  <li>la realización de todas las actividades relacionadas en el campo del sindicalismo;
  </li>
  <li>la realización de todas las actividades inherentes a los Contratos Colectivos de Trabajo;
<li>la realización de todas las actividades relacionadas a la asesoría en conflictos colectivos;
</li> 
<li>la prestación de toda clase de servicios relacionados con el objeto de la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA así como con las actividades accesorias y/o conexas a la misma;
</li>
<li>la actualización de Contratos Colectivos de Trabajo contratados con la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA; y
</li>
<li>la evaluación de los servicios brindados por la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA y los miembros que integran a la misma.
</li>
<li>4. Limitación de Uso o Divulgación de los Datos Personales
</li>
<p>Usted puede limitar el uso o divulgación de sus datos respecto de los servicios de la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA llamando a los teléfonos de la misma. En caso de que su solicitud resulte procedente, la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA lo registrará en el listado de exclusión.
</p>
<li>5. Medios para Ejercer los Derechos ARCO y/o revocar el consentimiento
</li>
<p>Conforme a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y su Reglamento, el titular de los Datos Personales, por sí o mediante representante legal, debiendo identificarse o acreditarse correctamente;  podrá ejercer sus derechos de Acceso, Rectificación, Cancelación y Oposición "Derechos ARCO",  así como revocar su consentimiento directamente llamando a los números telefónicos, describiendo de manera clara y precisa los datos personales respecto de los que se busca ejercer alguno de los Derechos ARCO.
</p>
<p>Es importante mencionarle que en la llamada para ejercer derechos ARCO deberá proporcionar una dirección de correo electrónico y/o número de teléfono a efecto de que podamos notificarle la respuesta a su solicitud o poder contactarlo en caso de requerir información adicional en relación con su petición.
</p>
<p>Asimismo, deberá enviarnos copia de su identificación oficial a efectos de acreditar su identidad. Por último, le informamos que se le responderá en un plazo máximo de 20 (veinte) días hábiles, contados desde la fecha en que cumpla con todos los requisitos establecidos en la solicitud de Acceso, Rectificación, Cancelación y Oposición, la determinación adoptada, a efecto de que, si resulta procedente, se haga efectiva la misma dentro de los 15 (quince) días hábiles siguientes a la fecha en que se le comunique respuesta. 
</p>
<li>6. Cambios al Aviso de Privacidad
</li>
<p>Cualquier cambio sustancial o total que se realice al presente Aviso de Privacidad podrá ser realizado por la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA y será comunicado a través de nuestra página de internet, o mediante correo electrónico a la dirección del cliente que para tales efectos mantenga la FEDERACIÓN OBRERA SINDICAL DE LA REPÚBLICA MEXICANA registrada.</p>

 </li>
</ul>
</div>

    </Container>
</section>
<Footer />
       </div>

  );
};

export default Privacy;
