import React, { useState, useEffect } from 'react';
import { Toast, Button } from 'react-bootstrap';
import Cookies from 'js-cookie';

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Verifica si el usuario ya aceptó las cookies
    const cookiesAccepted = Cookies.get('cookiesAccepted');
    if (!cookiesAccepted) {
      setShowBanner(true); // Muestra el banner si no aceptó
    }
  }, []);

  const handleAcceptCookies = () => {
    // Guarda una cookie indicando que las cookies fueron aceptadas
    Cookies.set('cookiesAccepted', 'true', { expires: 365 });
    setShowBanner(false); // Oculta el banner
  };

  return (
    <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', padding: '10px', zIndex: 1000 }}>
      <Toast show={showBanner} onClose={() => setShowBanner(false)}>
        <Toast.Header>
          <strong className="me-auto">Mensaje de Cookies</strong>
        </Toast.Header>
        <Toast.Body>
          Este sitio web utiliza cookies para mejorar la experiencia del usuario.
          <Button variant="success" onClick={handleAcceptCookies} style={{ marginLeft: '10px' }}>
            Aceptar cookies
          </Button>
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default CookieBanner;
