
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';


//importamos los comp creados
import Home from './pages/Home/Home';
import Servicios from './pages/Financiamiento/Financiamiento';
import Afiliacion from './pages/Afiliacion/Afiliacion';
import Estructura from './pages/Estructura/Estructura';
import Difusion from './pages/Difusion/Difusion';
import Objetivos from './pages/Objetivos/objetivos';
import Blog from './pages/Blog/Blog';
import Financiamiento from './pages/Financiamiento/Financiamiento';
import Aviso from './components/Aviso/Aviso';



function App() {
  return (
    
    <div className="App">
<BrowserRouter>
<Routes>
  <Route>
    <Route index element={ <Home/> } />
    <Route path='Home' element={ <Home /> } />
    <Route path='Servicios' element={ <Servicios /> } />
    <Route path='Afiliacion' element={ <Afiliacion /> } />
    <Route path='Estructura' element={ <Estructura /> } />
    <Route path='Difusion' element={ <Difusion/> } />
    <Route path='Objetivos' element={ <Objetivos/> } />
    <Route path='Financiamiento' element={ <Financiamiento/> } />
    <Route path='Blog' element={ <Blog/> } />
    <Route path='Aviso' element={ <Aviso/> } />


    <Route path='*' element={ <Navigate replace to="/"/> }/>
  </Route>
</Routes> 
</BrowserRouter>

    </div>
  );
}

export default App;
