import React, { useState } from 'react';
import Menu from '../../components/Navbar/Menu';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Footer from '../../components/Fotter/footer';
import './afiliacion.css'; 
import video from '../../assets/VID2.mp4';
import videoPoster from '../../assets/carousel1.png';
import emailjs from 'emailjs-com';
import Whatsapp from '../../components/Whatsapp/whatsapp';


function Afiliacion() {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    companyName: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.send('service_xegldek', 'template_3igjh37', formData, '_8v_Hvo4P88SblOEw')
      .then((response) => {
        console.log('Success:', response);
        setMessage('Mensaje enviado exitosamente!');
        setFormData({
          name: '',
          phone: '',
          email: '',
          companyName: '',
        });
      })
      .catch((error) => {
        console.error('Error:', error);
        setMessage('Error al enviar el mensaje.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className='SecAfiliacion'>
      <Menu/>
      <Whatsapp/>
      <div className='text-center m-5'>
        <h2 className='titlesec2'>¿Por qué AFILIARSE a la Organizacion?</h2>
        <p className='parrasec2'>Sé partícipe de la Asociación a través de capacitación, representación, soporte jurídico, asistencia y financiamientos para lograr así, incrementar la productividad y contribuir al desarrollo.</p>

        <Container className="d-flex justify-content-center">
          <Row className="my-4 w-100">
            <Col md={6} className="justify-content-center align-items-center video-presentation-containerr">
              <video className="video-element" controls poster={videoPoster}>
                <source src={video} type="video/mp4" width="100%" height="100%" />
                Your browser does not support the video tag.
              </video>
            </Col>
            <Col md={6} className="justify-content-center align-items-center">
              <div>
                <h2 className="text-center">Afiliarme</h2>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Group controlId="formName" className="mb-3">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Ingresa tu nombre"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="formPhone" className="mb-3">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control
                          type="text"
                          name="phone"
                          value={formData.phone}
                          onChange={handleChange}
                          placeholder="Ingresa tu teléfono"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>Correo</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Ingresa tu correo"
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formCompanyName" className="mb-3">
                    <Form.Label>Nombre de la Empresa</Form.Label>
                    <Form.Control
                      type="text"
                      name="companyName"
                      value={formData.companyName}
                      onChange={handleChange}
                      placeholder="Ingresa el nombre de la empresa"
                      required
                    />
                  </Form.Group>
                  <div className="text-center">
                    <Button id='BOTONFORMULARIO' className='custom-button' variant="primary" type="submit" disabled={isSubmitting}>
                      {isSubmitting ? 'Enviando...' : 'Enviar'}
                    </Button>
                  </div>
                  {message && <div className="text-center  mt-3">{message}</div>}
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer/>
    </div>
  );
}

export default Afiliacion;
