import React, { useState } from 'react';
import { Button, Row, Col, Card } from 'react-bootstrap';
import './menu2.css'; 


const Menu2 = () => {
  const [activeButton, setActiveButton] = useState('gallery1');

  const handleButtonClick = (gallery) => {
    setActiveButton(gallery);
  };

  const renderContent = () => {
    switch (activeButton) {
      case 'gallery1':
        return (
          <Card className="my-4 border-0" style={{ backgroundColor: '#F8F3EB' }}>
          <Row>
            <Col>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li>1.-Programas confiable.</li>
              <li>2.-Aprobación exitosa.</li>
              <li>3.-Asesoría Continua.</li>
            </ul>
            </Col>
          </Row>
        </Card>
        );
      case 'gallery2':
        return (
          <Card className="my-4 border-0" style={{ backgroundColor: '#F8F3EB' }}>
          <Row>
            <Col>
            <ul style={{ listStyleType: 'none', padding: 0, textAlign: 'center' }}>
            <li>1.-INE</li>
              <li>2.-Comprobante de Domicilio</li>
              <li>3.-Curp.</li>
              <li>4.-.</li>
            </ul>
            </Col>
          </Row>
        </Card>
        );
      case 'gallery3':
        return (
          <Card className="my-4 border-0" style={{ backgroundColor: '#F8F3EB' }}>
          <Row>
            <Col>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li>1.-MONTO:hasta 10 millones de pesos por (negocio, empresa o proyecto).</li>
              <li>2.-TASA:7% hasta 16% tasa fija.</li>
              <li>PLAZO: Hasta 240 meses (incluyendo periodo de gracia*).</li>
            </ul>
            </Col>
          </Row>
        </Card>
        );
      default:
        return null;
    }
  };

  return (
    <div>
        <Row className="my-5 text-center">
          <Col xs="12" sm="auto" className='botnn mx-2 mb-2'>
            <Button onClick={() => handleButtonClick('gallery1')}>
            CARACTERÍSTICAS <br/> DEL CRÉDITO
            </Button>
            {activeButton === 'gallery1' && <div className="arrow"></div>}
          </Col>
          <Col xs="12" sm="auto" className='botnn mx-2 mb-2'>
            <Button variant="secondary" onClick={() => handleButtonClick('gallery2')}>
            ¿QUÉ NECESITAS?
            </Button>
            {activeButton === 'gallery2' && <div className="arrow"></div>}
          </Col>
          <Col xs="12" sm="auto" className='botnn mx-2 mb-2'>
            <Button variant="success" onClick={() => handleButtonClick('gallery3')}>
            BENEFICIOS
            </Button>
            {activeButton === 'gallery3' && <div className="arrow"></div>}
          </Col>
        </Row>
        {renderContent()}
    </div>
  );
};

export default Menu2;
